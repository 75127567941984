<ng-template #headerTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <ng-content select="[header-picto]"></ng-content>
    </ng-container>
    <ng-container title>
      <ng-content select="[header-title]"></ng-content>
    </ng-container>
  </i2a-modal-profile-header-title>
</ng-template>

<ng-template #alertingTemplate>
  <div class="row pt-3 no-gutters" id="modal-body">
    <div *ngIf="informationKey" class="information">{{ informationKey | i18next }}</div>
    <ng-content select="[body]"></ng-content>
  </div>

  <div class="row" *ngIf="displayComment">
    <div class="form-group col-12" id="modal-comment">
      <label for="assignIssue">{{ 'profile.comments' | i18next }}</label>
      <div>
        <textarea [(ngModel)]='validation.comments' rows="5" class="comments" (ngModelChange)="onCommentsChange()">
        </textarea>
      </div>
    </div>
  </div>
</ng-template>

<i2a-base-modal [modalTitle]="modalTitle" [activateFullScreen]="activateFullScreen" [noOverflow]="noOverflow">
  <ng-container body>
    <div class="profile-alert-base-modal">
      <div id="modal-header" class="row id-header">
        <table class="table-sm id-table" *ngIf="resourceProfile">
          <tbody>
            <tr>
              <td scope="row text-center" class="p-0 id-header-picto">
                <img class="id-provider-picture" *ngIf="providerPhotoUrl" [src]="providerPhotoUrl" width="28"
                  height="28">
              </td>
              <td scope="row" class="text-break w-100 align-self-center" #headertitle>
                <span class="provider-type" [title]="providerTypeLabel">{{ providerTypeLabel }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table-sm id-table" *ngIf="user">
          <tbody>
            <tr>
              <td scope="row text-center" class="p-0 id-header-picto">
                <i2a-user-photo [userId]="user.id" [size]="28">
                </i2a-user-photo>
              </td>
              <td scope="row" class="text-break w-100 align-self-center" #headertitle>
                <span class="provider-type" [title]="user.firstName + ' ' + user.lastName">{{ user.firstName }}
                  {{ user.lastName }}</span>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <ng-container *ngIf="loading">
        <div class="row pt-3 no-gutters" id="modal-body">
          <div class="col-centered">
            <span> {{ 'profile.loading' | i18next }}</span>
            <br />
            <i2a-spinner></i2a-spinner>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading">
        <ng-container *ngIf="manualRemediation && !disableManualRemediation">
          <div class="card">
            <div class="card-body p-3">
              <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>

              <div class="container-fluid">
                <div class="row no-gutters mr-auto">

                  <div class="col-centered mb-2 font-weight-bold">
                    <span> {{ 'applyCorrection.howToFix' | i18next }}</span>
                  </div>

                </div>
                <div class="row pl-3 pr-3"
                  [innerHtml]="manualDescriptionKey | i18next : { link: ( manualLinkKey | i18next ) } | SafeHtml">
                </div>
                <div class="row no-gutters mr-auto">

                  <div class="col-centered mb-2 font-weight-bold">
                    <span> {{ 'applyCorrection.otherwise' | i18next }}</span>

                  </div>
                </div>
                <div class="row pl-3 pr-3" *ngIf="manualRemediation && !disableManualRemediation"
                  [innerHtml]="automaticDescriptionKey | i18next | SafeHtml"></div>
              </div>
              <div ngbAccordion #accordion="ngbAccordion" (hide)="beforeChange($event)" (show)="beforeChange($event)" [destroyOnHide]='false' [closeOthers]="true" activeIds="remediate">

                <div ngbAccordionItem id="alert">

                  <div ngbPanelHeader>
                    <button class="btn btn-link p-2 button-shadow-none" ngbAccordionButton>
                      <div class="float-left m-1 ">
                        <fa-icon class="pr-2" [icon]="['fal', 'exclamation-triangle']" size="lg"></fa-icon>
                        <span>Alert</span>
                      </div>
                      <div class="float-right m-1">
                        <ng-container *ngIf="!ngbAccordion.isExpanded('alert')">
                          <fa-icon [icon]="['fal', 'chevron-down']" size="lg"></fa-icon>
                        </ng-container>
                        <ng-container *ngIf="ngbAccordion.isExpanded('alert')">
                          <fa-icon [icon]="['fal', 'chevron-up']" size="lg"></fa-icon>
                        </ng-container>
                      </div>
                    </button>
                  </div>

                  <div ngbAccordionCollapse>

                    <div ngbAccordionBody>
                      <ng-container *ngTemplateOutlet="alertingTemplate"></ng-container>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!manualRemediation">
          <div class="card">

            <div class="card-body">
              <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>

              <ng-container *ngTemplateOutlet="alertingTemplate"></ng-container>

            </div>

          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container message>
    <ng-content select="[message]"></ng-content>
  </ng-container>

  <ng-container footer>
    
    <div class="col" *ngIf="!loading && session.IsEnableEscalationDirectUser">

      <span class="text-break">
        {{ "applyCorrection.escalationDirectUser" | i18next }}
      </span>
      
      <i2a-input-checkbox 
        [(model)]="validation.sendNotificationDirectUser"
        [className]="'btn-outline-dark'"
        [classNameChecked]="'btn-outline-danger'"
        [valueChecked]="true"
        [valueUnChecked]="false">
      </i2a-input-checkbox>

    </div>

    <div class="col-centered" *ngIf="!loading">
      <button *ngIf="displayHelp && (!manualRemediation || alertOpened)" (click)="help()" class="btn btn-help">
        <fa-icon [icon]="['fas', 'question']" size="lg"></fa-icon>
      </button>
      <button (click)="close()" class="btn btn-outline-dark ml-2">{{'button.cancel' | i18next }}</button>
      <button *ngIf="displaySend  && (!manualRemediation || alertOpened)" (click)="send()" ngbAutofocus type="submit"
        [disabled]="buttonSendIsDisabled || loading" class="btn btn-red ml-2">{{'button.send' | i18next }}</button>
      <button *ngIf="!displaySend  && (!manualRemediation || alertOpened)" (click)="ok()" ngbAutofocus type="submit"
        [disabled]="loading" class="btn btn-red ml-2">{{'button.ok' | i18next }}</button>
    </div>
  </ng-container>

</i2a-base-modal>