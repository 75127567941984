import { Component, OnInit, Input, Inject } from '@angular/core';
import { ReportProfileRulesComputedDelegate, ReportProfileAlertRulesComputedDelegateModel, ProviderAuditResourceKind, OneDriveReportProfileRulesComputedDelegate, Constants, MicrosoftOfficeReportProfileAlertRulesComputedDelegateModel, ZimbraReportProfileAlertRulesComputedDelegateModel, BaseProviderInstanceResourceModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { RulesComputedDelegateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/rules-computed-delegate-configuration';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-rules-computed-delegate-modal',
  templateUrl: './rules-computed-delegate-modal.component.html',
  styleUrls: ['./rules-computed-delegate-modal.component.scss']
})
export class RulesComputedDelegateModalComponent implements OnInit {
  @Input() public providerInstanceResource: BaseProviderInstanceResourceModel;
  @Input() public delegate: ReportProfileRulesComputedDelegate;
  @Input() public configuration: RulesComputedDelegateConfiguration;
  @Input() public IsInstantMessaging: boolean;
  @Input() public IsInbox: boolean;
  @Input() public IsFolders: boolean;
  @Input() public IsCalendar: boolean;

  @Input() public displayIsInbox: boolean;
  @Input() public displayIsFolders: boolean;
  @Input() public displayIsCalendar: boolean;
  @Input() public displayIsInstantMessaging: boolean;
  
  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  
  public devices: any[];

  public validation: ReportProfileAlertRulesComputedDelegateModel;
  public canSend: boolean;
  public folders: any[];
  public kinds: any[];
  public name: string;


  public get isMicrosoft(): boolean {
    return this.providerInstanceResource.providerId == Constants.PROVIDER_ID_MICROSOFT;
  }

  public get isZimbra(): boolean {
    return this.providerInstanceResource.providerId == Constants.PROVIDER_ID_ZIMBRA;
  }

  public get isOneDrive(): boolean {
    return this.isMicrosoft && this.providerInstanceResource.providerTypeId == Constants.PROVIDER_TYPE_PERSONAL_STORAGE;
  }

  public get isExchange(): boolean {
    return this.isMicrosoft && this.providerInstanceResource.providerTypeId == Constants.PROVIDER_TYPE_MAILING;
  }

  public get isFileShare(): boolean {
    return this.isMicrosoft && this.providerInstanceResource.providerTypeId == Constants.PROVIDER_TYPE_FILE_SHARE;
  }

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public session: SessionService
  ) {
    this.canSend = false;
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = this.configuration.viewDetailFolders && (this.validation.folders.length > 0 || this.validation.allFolders)
      this.canSend = this.canSend || (this.configuration.viewDetailKinds);
      this.canSend = this.canSend || (this.configuration.displayRegisteredDevices && this.validation.deviceIds.length > 0);
      if (this.isMicrosoft) {
        var msValidation = (this.validation as MicrosoftOfficeReportProfileAlertRulesComputedDelegateModel);
        this.canSend = this.canSend || (!this.configuration.viewDetailKinds && !this.configuration.viewDetailFolders && this.configuration.viewIconBox &&
                                         (!msValidation.calendarIsValid || !msValidation.inboxIsValid || !msValidation.folderIsValid || !msValidation.instantMessagingShareIsValid));
      }
      else if (this.isZimbra) {
        var zimbraValidation = (this.validation as ZimbraReportProfileAlertRulesComputedDelegateModel);
      }
    });
  }

  ngOnInit() {
    this.folders = [];
    this.kinds = [];
    this.devices = [];
    if (this.delegate && this.providerInstanceResource) {
      this.delegate.registeredDevices.forEach(device => {
        this.devices.push({
          "deviceType": device.deviceType,
          "name": device.name,
          "id": device.id,
          "checked": false
        });
      });
      if (this.isMicrosoft) {
        let msValidation = new MicrosoftOfficeReportProfileAlertRulesComputedDelegateModel();
        this.validation = msValidation;
        msValidation.instantMessagingShareIsValid = true;
        msValidation.calendarIsValid = true;
        msValidation.folderIsValid = true;
        msValidation.inboxIsValid = true;
      }
      else if (this.isZimbra) {
        this.validation = new ZimbraReportProfileAlertRulesComputedDelegateModel();        
      }
      this.validation.deviceIds = [];
      this.validation.allFolders = false;
      this.validation.folders = [];
      this.validation.delegate = this.delegate;
      this.validation.kinds = <any>0;

      if (this.configuration.viewDetailFolders) {
        this.delegate.resourceFolders.forEach(folder => {
          this.folders.push({
            "name": folder,
            "checked": false
          });
        });
        if (this.isOneDrive) {
          (this.delegate as OneDriveReportProfileRulesComputedDelegate).instantMessagingFolders.forEach(folder => this.folders.push({
            "name": folder,
            "checked": false
          }));
        }
        this.validation.allFolders = false;
        this.folders.sort();
      }


      if (this.configuration.viewDetailKinds) {
        for (var enumMember in ProviderAuditResourceKind) {
          var enumMemberInt = parseInt(enumMember, 10);
          if (enumMemberInt >= 0) {
            if (this.configuration.kindToFilter && this.configuration.kindToFilter.indexOf(ProviderAuditResourceKind[enumMember]) == -1
              && this.hasFlag(this.delegate.resourceKind, enumMemberInt)) {
              this.kinds.push({
                "id": enumMemberInt,
                "name": ProviderAuditResourceKind[enumMember],
                "checked": false
              });
            }
          }
        }
        if (this.kinds.length == 1) {
          this.kinds[0].checked = true;
        }
      }
      if (this.isMicrosoft && this.configuration.viewIconBox && !this.configuration.viewDetailKinds && !this.configuration.viewDetailFolders ) {
        let msValidation = (this.validation as MicrosoftOfficeReportProfileAlertRulesComputedDelegateModel);
        if (this.isOneDrive) {
          msValidation.calendarIsValid = true;
          msValidation.inboxIsValid = true;
        if (!this.IsFolders && !this.IsInstantMessaging) {
            msValidation.instantMessagingShareIsValid = true;
            msValidation.folderIsValid = true;
          }
          else {
            msValidation.instantMessagingShareIsValid = false;
            msValidation.folderIsValid = false;
          }
        }
        else if (this.isExchange) {
          msValidation.instantMessagingShareIsValid = true;
          if (!this.IsFolders && !this.IsInbox && !this.IsCalendar) {
            msValidation.calendarIsValid = true;
            msValidation.folderIsValid = true;
            msValidation.inboxIsValid = true;
          }
          else {
            msValidation.calendarIsValid = (this.IsFolders || this.IsInbox);
            msValidation.folderIsValid = this.IsCalendar || this.IsInbox;
            msValidation.inboxIsValid = this.IsFolders || this.IsCalendar;
          }
        }
      }
      if (!this.delegate.isNameKey) {
        this.name = this.delegate.name;
      }
      else {
        this.name = this.i18Next.t(this.delegate.name);
      }
      this.setCanSend();
    }
  }

  folderChecked(isChecked: boolean, folder: any) {
    const index: number = this.validation.folders.indexOf(folder.name);
    if (isChecked && index === -1) {
      this.validation.folders.push(folder.name);
    }
    else if (!isChecked && index !== -1) {
      this.validation.folders.splice(index, 1);
    }
    if (this.folders.length == this.validation.folders.length) {
      this.allFoldersChecked(true);
      setTimeout(() => {
        this.validation.allFolders = true;
      });
    }
    this.setCanSend();
  }

  allFoldersChecked(isChecked: boolean) {
    if (isChecked) {
      this.folders.forEach(f => f.checked = false);
      this.validation.folders = [];
      this.setCanSend();
    }
  }

  kindChecked(isChecked: boolean, kind: any) {
    if (isChecked) {
      this.validation.kinds = this.validation.kinds | kind.id;
    }
    else {
      this.validation.kinds = this.validation.kinds & ~kind.id;
    }
    this.setCanSend();
  }

  hasFlag(resourceKind: ProviderAuditResourceKind, flag: ProviderAuditResourceKind): boolean {
    return (resourceKind & flag) == flag;
  }

  checked(isChecked: boolean, device: any) {
    const index: number = this.validation.deviceIds.indexOf(device.id);
    if (isChecked && index === -1) {
      this.validation.deviceIds.push(device.id);
    }
    else if (!isChecked && index !== -1) {
      this.validation.deviceIds.splice(index, 1);
    }
    this.setCanSend()
  }
}
