import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { DatasourceService } from '@i2a/web-api-client';
import { UrlBuilderHelperService, IWebConfig } from '@i2a/web-core';
import { IConfigService, I2A_CONFIG_SERVICE } from '@i2a/web-core';

@Injectable()
export class ApiEndPointResolver  {
  constructor(
    @Inject(I2A_CONFIG_SERVICE) private configService: IConfigService<IWebConfig>,
    private datasoure: DatasourceService,
    private urlBuilderHelper: UrlBuilderHelperService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<any> | Promise<any> | any> {
    console.log(`set apiEndPoint for ${route.params["tenantCode"]}`);
    let apiEndPoint = this.urlBuilderHelper.GetWSTenantizedBaseUrl(this.configService.Config, route.params["tenantCode"])
    this.datasoure.ApiEndPoint = apiEndPoint;
    return apiEndPoint;
  }
}