import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route, ExtraOptions } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthenticationGuardService, WebCoreRoutesService } from '@i2a/web-core';
import { TenantsComponent } from './pages/tenants/tenants.component';
import { ApiEndPointResolver } from './resolvers/api-end-point.resolver';
import { TenantListComponent } from './pages/tenant-list/tenant-list.component';
import { ConnectedAccountResolver } from './resolvers/connected-account.resolver';
import { TenantCodeResolver } from './resolvers/tenant-code.resolver';
import { ProviderInstanceProfileComponent } from './pages/provider-instance-profile/provider-instance-profile.component';

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    onSameUrlNavigation: "reload"
};

const routes: Routes = [
  { path: '', redirectTo: 'tenants', pathMatch: "full" },
  {
    path: 'tenants', component: TenantsComponent, resolve: { apiEndPoint: ApiEndPointResolver, connectedAccount: ConnectedAccountResolver }, canActivate: [AuthenticationGuardService],
    children: [
      { path: '', component: TenantListComponent, resolve: { tenantCode: TenantCodeResolver, apiEndPoint: ApiEndPointResolver }, canActivate: [AuthenticationGuardService] },
      {
        path: ':tenantCode', resolve: { tenantCode: TenantCodeResolver, apiEndPoint: ApiEndPointResolver }, canActivate: [AuthenticationGuardService],
        children: [
          { path: '', redirectTo: "dashboard", pathMatch: "full" },
          { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuardService] },
          {
            path: 'user/:userId', canActivate: [AuthenticationGuardService], children: [
              { path: '', redirectTo: "profile", pathMatch: "full" },
              {
                path: 'profile', canActivate: [AuthenticationGuardService], children: [
                  { path: '', component: ProfileComponent, canActivate: [AuthenticationGuardService] },
                  { path: ':providerId/:providerInstanceId', component: ProviderInstanceProfileComponent, canActivate: [AuthenticationGuardService] }
                ]
              }
            ]
          }
        ]
      }]
  },
  { path: '**', redirectTo: 'not-found', pathMatch: 'full' },
  { path: 'not-found', component: NotFoundComponent }
];


@NgModule({
  imports: [RouterModule.forRoot([...WebCoreRoutesService.GetRoutes(), ...routes], routerOptions)],//, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
