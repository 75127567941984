import { Component, OnInit, OnDestroy, Input, ViewChildren, QueryList } from '@angular/core';
import { Subscription, timer, Observable, from, of } from 'rxjs';
import { Constants, MicrosoftOfficeReportProfileModel, MicrosoftOfficeProfileProviderDatasourceService, ProviderInstanceModel, UserModel, ProviderInstancesDatasourceService, ConnectedAccountModel, UsersDatasourceService, ProviderInstanceResourceModel, ProviderAuditResourceKind } from '@i2a/web-api-client';
import { ProfilePageComponent } from '../../../shared/profile-page/profile-page.component';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { Template } from 'projects/web-user-profile/src/app/models/templates/template';
import { mergeMap, concatMap, delay } from 'rxjs/operators';
import { ExchangeConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/exchange-configuration';
import { ExchangeReportProfileFolderRight } from '@i2a/web-api-client';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'i2a-microsoft-profile',
  templateUrl: './microsoft-profile.component.html',
  styleUrls: ['./microsoft-profile.component.scss']
})
export class MicrosoftProfileComponent implements OnInit, OnDestroy {
  @Input() public providerInstance: ProviderInstanceModel;
  @Input() public user: UserModel;
  @Input() public userView: boolean;
  @ViewChildren(ProfilePageComponent) profilePageComponent: QueryList<ProfilePageComponent>;

  private subscriptions = new Subscription();
  public profile: MicrosoftOfficeReportProfileModel;
  public template: Template;
  public collectedResources: ProviderInstanceResourceModel[];
  public collectedResourcesLoaded: ProviderInstanceResourceModel[];
  public loadingRes: ProviderInstanceResourceModel;
  public collectedResourcesIndex: number;
  public collectedResourcesCount: number;
  public isAlert?: boolean;
  public alertIsSent: boolean;
  public connectedAccount: ConnectedAccountModel;
  public noCollectedResources: boolean;
  public errorPendingCollect:boolean;
  public errorPendingCollectObject: HttpErrorResponse;

  public progressValue: number;
  public progress: any;
  public ressourceLoadingObserver: any;

  public loading: boolean = true;
  public start: boolean = false;
  
  constructor(
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    private ProviderInstancesDatasource: ProviderInstancesDatasourceService,
    private usersDataSource: UsersDatasourceService,
    private session: SessionService,
  ) {
    this.isAlert = true;
    this.alertIsSent = false;
    this.noCollectedResources = false;
    this.errorPendingCollect= false;
  }

  ngOnInit() {
    if (this.user && this.providerInstance) {
      this.progressValue = 2;
      this.progress = timer(0, 250).subscribe(() => {
        this.progressValue = this.increment();
      }, 
      error => { return this.myErrorAction(error); });
      this.subscriptions.add(this.usersDataSource.loadCollectedResources(this.user.id).subscribe(
        receivedCollectedResources => {
          var providerInstanceResources = receivedCollectedResources.find(a => a.providerInstance.providerId == this.providerInstance.providerId
            && a.providerInstance.providerInstanceId == this.providerInstance.providerInstanceId).providerInstanceResources;
          this.collectedResources = providerInstanceResources.filter(a => a.isRealTimeProcessing || a.isPermanentAuditing).sort((a, b) => a.providerTypeId.localeCompare(b.providerTypeId));

          this.collectedResourcesCount = this.collectedResources.length;
          if (this.collectedResources.length > 0) {
            var index = 0;
            this.loadingRes = this.collectedResources[index];
            this.collectedResourcesLoaded = new Array<ProviderInstanceResourceModel>();
            this.ressourceLoadingObserver = new Observable(observer => {
              observer.next(this.collectedResources);
              observer.complete();
            })
              .pipe(
                // make observable to emit each element of the array (not the whole array)
                mergeMap((x: any) => from(x)),
                // delay each element by 4.8 sec
                concatMap(x => of(x).pipe(delay(4800)))
              )
              .subscribe(() => {
                if (this.collectedResources.length > index) {
                  this.loadingRes = this.collectedResources[index + 1];
                }
                this.collectedResourcesLoaded.push(this.collectedResources[index]);
                this.collectedResourcesIndex = index;
                index = index + 1;
              }, 
              error => { return this.myErrorAction(error); });
          }
          this.session.UserView = this.userView;
          this.subscriptions.add(this.ProviderInstancesDatasource.loadMyProfileTemplate(this.providerInstance.providerId, this.providerInstance.providerInstanceId, this.user.id, this.userView)
            .subscribe(template => {
              this.template = <Template>template;
              this.session.ManualRemediation = this.template.configuration?.manualRemediation ?? false;               
              this.session.IgnoreCommentToSend = this.template.configuration?.ignoreCommentToSend ?? true;   
              this.session.IsEnableEscalationDirectUser = this.template.configuration?.isEnableEscalationDirectUser ?? true;        
              this.subscriptions.add(this.microsoftDatasource.loadProfile(this.providerInstance.providerInstanceId, this.user.id)
                .subscribe(profile => {
                  if (profile != null) {
                    this.profile = this.applyFilters(profile);
                  } else {
                    this.noCollectedResources = true;
                  }
                  this.progressValue = 100;
                  this.progress.unsubscribe();
                  if (this.ressourceLoadingObserver) {
                    this.ressourceLoadingObserver.unsubscribe();
                    for (let index = this.collectedResourcesLoaded.length; index < this.collectedResources.length; index++) {
                      delay(200);
                      this.collectedResourcesLoaded.push(this.collectedResources[index]);
                    }
                  }
                  this.loadingRes = null;
                  this.loading = false;
                }, 
                error => { return this.myErrorAction(error); }));
            }, 
            error => { return this.myErrorAction(error); }));
        }, 
        error => { return this.myErrorAction(error); })
      );
      this.subscriptions.add(this.session.Account$.subscribe((account) => {
        this.connectedAccount = account;
      }, 
      error => { return this.myErrorAction(error); }));
    }
  }

  private applyFilters(profile: MicrosoftOfficeReportProfileModel): MicrosoftOfficeReportProfileModel {
    var msProvider = this.template.providerTemplates.find(p => p.providerId.localeCompare(Constants.PROVIDER_ID_MICROSOFT, undefined, {sensitivity:'base'}) == 0);
    var exchangeTemplate = msProvider.providerApplicationTemplates.find(p=> p.providerTypeId.localeCompare(Constants.PROVIDER_TYPE_MAILING, undefined, {sensitivity:'base'}) == 0);
    var configuration =  <ExchangeConfiguration>exchangeTemplate.containerModule.configuration;

    if (!configuration.viewFolderVisible) {
      profile.exchangeProfiles.forEach(exProfile => {
        var removePermissions = [];
        exProfile.permissions.forEach(permission => {
          var removeFolders = [];
          Object.keys(permission.exchangeFoldersRights).forEach(folderKey => {
            var newValue = (permission.exchangeFoldersRights[folderKey] ^ ExchangeReportProfileFolderRight.FolderVisible) & permission.exchangeFoldersRights[folderKey]
            if (newValue == 0) {
              removeFolders.push(folderKey);
            }
          });
          removeFolders.forEach(folderKey => {
            delete permission.exchangeFoldersRights[folderKey];
            delete permission.foldersIdentity[folderKey];
            delete permission.foldersRights[folderKey];
          })
          permission.resourceKind = <any>0;
          Object.keys(permission.foldersRights).forEach(resourceKind => 
            {
              permission.resourceKind = permission.resourceKind | permission.foldersRights[resourceKind];
            });
          permission.hasFoldersRights = permission.resourceKind > 0 && permission.resourceKind != ProviderAuditResourceKind.Calendar;

          if (permission.calendarIsFolderVisible) {
            permission.hasCalendarRights = false;
          }
          if (Object.keys(permission.exchangeFoldersRights).length == 0 
              && !permission.hasFullAccessRights 
              && !permission.hasSendAsRights 
              && !permission.hasCalendarRights) {
                removePermissions.push(permission);
              }
        });

        exProfile.permissions = exProfile.permissions.filter(p => !removePermissions.some(p2=> p2 == p));
      });
    }
    return profile;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private increment(rnd = 0) {
    const stat = this.progressValue;
    if (stat >= 99) {
      rnd = 0;
    }

    if (rnd === 0) {
      if (stat >= 0 && stat < 25) {
        // Start out between 1 - 2% increments
        rnd = (Math.random() + 1);
      } else if (stat >= 25 && stat < 65) {
        // increment between 0 - 2%
        rnd = (Math.random() * 2);
      } else if (stat >= 65 && stat < 90) {
        // increment between 0 - 1%
        rnd = (Math.random() * 1);
      } else if (stat >= 90 && stat < 99) {
        // finally, increment it .5 %
        rnd = 0.25;
      } else {
        // after 99%, don't increment:
        rnd = 0;
      }
    }

    return rnd + stat;
  }

  private myErrorAction(error: any): void {
    this.errorPendingCollect = true
    this.errorPendingCollectObject = error;
    this.loading = false;
    this.progressValue = 0;
    this.progress?.unsubscribe();
    this.ressourceLoadingObserver?.unsubscribe();
  }
}
